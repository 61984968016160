import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setAguardando(aguardando) {
    return {
        type: 'ANALISE_ACESSO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro) {
    return {
        type: 'ANALISE_ACESSO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function registrarAcesso(tela, menu, tempo) {
		
    return (dispatch, getState) => {
	    axios.post(`${consts.API_URL}/analiseAcesso`, {
			sistema: 'Letsara',
			tela: tela,
			menu: menu,
			tempo: tempo,
			id_usuario: getState().auth.usuario.id
		}).then(resp => {
		}).catch(e => {
			console.log(e);
		});
    };
}

export function getListaUsuario() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/usuario/listarSelect`)
		.then(resp => {
			dispatch({
		        type: 'ANALISE_ACESSO_USUARIO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaMaisAcessadosTempo() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/analiseAcesso/listarMaisAcessadosTempo?${getState().analiseAcesso.filtro.id_usuario ? `id_usuario=${getState().analiseAcesso.filtro.id_usuario}` : ''}
				&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_inicial)}
				&data_final=${DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'ANALISE_ACESSO_MAIS_ACESSADOS_TEMPO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaMaisAcessadosQuantidade() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/analiseAcesso/listarMaisAcessadosQuantidade?${getState().analiseAcesso.filtro.id_usuario ? `id_usuario=${getState().analiseAcesso.filtro.id_usuario}` : ''}
				&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_inicial)}
				&data_final=${DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'ANALISE_ACESSO_MAIS_ACESSADOS_QUANTIDADE_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaMaisAcessadosUsuario() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/analiseAcesso/listarMaisAcessadosUsuario?${getState().analiseAcesso.filtro.id_usuario ? `id_usuario=${getState().analiseAcesso.filtro.id_usuario}` : ''}
				&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_inicial)}
				&data_final=${DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().analiseAcesso.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'ANALISE_ACESSO_MAIS_ACESSADOS_USUARIO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}