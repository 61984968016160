const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaComissaoOperacao: [],
	listaComissaoTermo: [],
	listaComissaoVinculo: [],
	listaUsuario: [],
	listaComissaoTipo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMISSAO_APURACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'COMISSAO_APURACAO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'COMISSAO_APURACAO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'COMISSAO_APURACAO_OPERACAO_LISTADO':
		return {
			...state,
			listaComissaoOperacao: action.payload.data
		};

		case 'COMISSAO_APURACAO_TERMO_SELECT_LISTADO':
		return {
			...state,
			listaComissaoTermo: action.payload.data
		};

		case 'COMISSAO_APURACAO_VINCULO_SELECT_LISTADO':
		return {
			...state,
			listaComissaoVinculo: action.payload.data
		};

		case 'COMISSAO_APURACAO_USUARIO_SELECT_LISTADO':
		return {
			...state,
			listaUsuario: action.payload.data.filter(item => item.comissao_variavel)
		};

		case 'COMISSAO_APURACAO_COMISSAO_SELECT_LISTADO':
		return {
			...state,
			listaComissaoTipo: action.payload.data
		};			

        default:
            return state;
    }
}
