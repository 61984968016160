import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './integracaoLetsaraForm';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import DateFormat from '../common/dateFormat/DateFormat';

import { getLista, getListaLog } from './integracaoLetsaraActions';
import { setSuccess } from '../common/toast/toast';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

class IntegracaoLetsaraLog extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		filtro: {
			data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			id_integracao: null
		}
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaLog(this.state.filtro);
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'alterarSenha' ? (
						<Form alterarSenha onSubmit={this.props.alterarSenha} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<LabelAndInputMask
							maxWidth={130}
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 4 3'
							mask='99/99/9999'
							value={this.state.filtro.data_inicial}
							onChange={data => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										data_inicial: data.target.value
									}
								});
							}} />
						
						<LabelAndInputMask
							maxWidth={130}
							label='Data Final' placeholder='Informe a data'
							cols='12 6 4 3'
							mask='99/99/9999'
							value={this.state.filtro.data_final}
							onChange={data => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										data_final: data.target.value
									}
								});
							}} />
							
						<Select
							options={this.props.lista.map(item => ({ id: item.id, valor: item.nome }))}
							label='Integração'
							cols='12 6 4 4'
							placeholder='Selecione'
							value={this.state.filtro.id_integracao}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_integracao: value
									}
								});
							}} />
						
						<div style={{ marginTop: 8 }}>
							<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.getLista();
									this.props.getListaLog(this.state.filtro);
								}}>
								<i class='fas fa-sync-alt'></i> Atualizar
							</a>
						</div>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive alturaFixa maxHeight={window.innerHeight - 200}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th width={100} sticky alignCenter>Data/Hora</Th>
								<Th width={200} sticky alignCenter>Integração</Th>
								<Th width={100} sticky alignCenter>Status</Th>
								<Th width={400} sticky alignCenter>Mensagem de Erro</Th>
								<Th width={10} sticky alignCenter>Registros</Th>
								<Th width={10} sticky alignCenter>Retorno</Th>
								<Th width={10} sticky alignCenter>Tempo(s)</Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaLog.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{item.id_integracao} - {item.nome_integracao}</Td>
									<Td alignCenter>
										{item.status_retorno == 'Sucesso' ? (
											<span class='badge badge-success' style={{ fontSize: 14, width: 90, padding: 6 }}>Sucesso</span>
										) : (
											<span class='badge badge-danger' title={item.erro} style={{ fontSize: 14, width: 90, padding: 6 }}>Erro</span>
										)}
									</Td>
									<Td alignCenter>{item.mensagem_erro}</Td>
									<Td alignCenter>{item.quatidade_retorno}</Td>
									<Td alignCenter>
										{((item.mensagem_retorno.length || []) > 0) &&
										<ButtonTable
											type={'primary'}
											icon={'fas fa-copy'}
											visible={true}
											event={() => {
												navigator.clipboard.writeText(JSON.stringify(item.mensagem_retorno));
												setSuccess('Copiado com sucesso');
											}} />}
									</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(parseInt(item.tempo_operacao_ms) / 1000, 2)}</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.integracaoLetsara,
	modoTela: state.integracaoLetsara.modoTela,
	lista: state.integracaoLetsara.lista,
	listaLog: state.integracaoLetsara.listaLog
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, getListaLog }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(IntegracaoLetsaraLog);
