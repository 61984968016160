import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm
} from './acessorioTipoActions';
import { setError } from '../common/toast/toast';

class AcessorioTipoForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorArquivo = document.createElement('input');
		fileSelectorArquivo.setAttribute('type', 'file');
		fileSelectorArquivo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {
					this.props.initForm({
						...this.props.formularioValues,
						imagem: reader.result
					});
				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorArquivo = fileSelectorArquivo;
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<label>Imagem</label>
							</Grid>
							{this.props.formularioValues.imagem &&
							<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
								<span class="btn btn-outline-primary fileinput-button dz-clickable">
									<img src={this.props.formularioValues.imagem} style={{ height: 300 }} />
								</span>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingRight: 2, marginTop: 4 }}>
										
									<button
										type='button'
										class='btn btn-flat btn-outline-danger'
										style={{ width: '100%' }}
										disabled={readOnly}
										onClick={() => {
											this.props.initForm({
												...this.props.formularioValues,
												imagem: null
											});
										}}>
										<i class='fas fa-trash-alt'></i>
									</button>
								</div>
							</div>}
							{(!readOnly && !this.props.formularioValues.imagem) ? (
								<span class="btn btn-outline-primary fileinput-button dz-clickable" 
									style={{ marginLeft: 16 }}
									onClick={() => this.fileSelectorArquivo.click()}>
									<img src={imagemAdd} style={{ height: 30 }} />
								</span>
							) : null}
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

AcessorioTipoForm = reduxForm({form: 'acessorioTipoForm', destroyOnUnmount: false})(AcessorioTipoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('acessorioTipoForm')(state),
	registro: state.acessorioTipo.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioTipoForm);
