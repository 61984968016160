const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaLog: [], listaNegociador: [], listaOperacoes: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
		case 'INTEGRACAO_LETSARA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'INTEGRACAO_LETSARA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };

        case 'INTEGRACAO_LETSARA_LOG_LISTADO':
            return { ...state, listaLog: action.payload.data };

        case 'INTEGRACAO_LETSARA_NEGOCIADOR_LISTADO':
            return { ...state, listaNegociador: (action.payload.data || []).map(item => ({ id: parseInt(item.id_negociador), valor: item.negociador, operacoes: item.operacoes })) };

        case 'INTEGRACAO_LETSARA_OPERACAO_LISTADO':
            return {
                ...state,
                listaOperacoes: (action.payload.data || []).filter(item => item.id != 575).map(item => ({ id: parseInt(item.codigo), valor: item.valor })) //EXCECAO_GERAL
            };

        default:
            return state;
    }
}
