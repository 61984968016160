import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import InputPesquisa from '../common/form/inputPesquisa';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Select from '../common/form/select';

import { 
	setFiltro, getListaUsuario,
	getListaMaisAcessadosTempo, getListaMaisAcessadosQuantidade, getListaMaisAcessadosUsuario
} from './analiseAcessoActions';
import BarChart from '../common/grafico/BarChart';

class AnaliseAcesso extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getListaUsuario();
        this.props.getListaMaisAcessadosTempo();
		this.props.getListaMaisAcessadosQuantidade();
		this.props.getListaMaisAcessadosUsuario();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
                </Content>
            </div>
        )
    }
	
	lista() {

		const ExcelFile = ReactExport.ExcelFile;

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<Row>
							<LabelAndInputMask
								label='Data Incial' placeholder='Informe a data'
								cols='12 6 2 1'
								mask='99/99/9999'
								minWidth={130}
								maxWidth={130}
								value={this.props.filtro.data_inicial}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								}} />

							<LabelAndInputMask
								label='Data Final' placeholder='Informe a data'
								cols='12 6 2 1'
								mask='99/99/9999'
								minWidth={130}
								maxWidth={130}
								value={this.props.filtro.data_final}
								onChange={data => {
									this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								}} />

							<Select
								label='Usuário'
								options={this.props.listaUsuario.filter(item => ({ id: item.id, valor: item.nome }))}
								cols='12 6 3 3'
								placeholder='Selecione'
								minWidth={300}
								value={this.props.filtro.id_usuario}
								onChange={value => {
									this.props.setFiltro({ ...this.props.filtro, id_usuario: value });
								}} />

							<div style={{ marginTop: 8 }}>
								<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
									onClick={() => {
										this.props.getListaMaisAcessadosTempo();
										this.props.getListaMaisAcessadosQuantidade();
										this.props.getListaMaisAcessadosUsuario();
									}}>
									<i class='fas fa-sync-alt'></i> Atualizar
								</a>
							</div>
						</Row>
					</ContentCardHeader>
				</ContentCard>

				{this.graficoMaisAcessadosTempo()}

				{this.graficoMaisAcessadosQuantidade()}

				{this.graficoMaisAcessadosUsuario()}

			</div>
		);
	}

	graficoMaisAcessadosTempo() {
		

		let lista = ['Acessos (horas)'].map(item => {
			return {
				serie: item,
				categorias: this.props.listaMaisAcessadosTempo.map((categoria, i) => ({
					ordem: i + 1,
					nome: `${categoria.tela}`,
					quantidade: categoria.tempo_hora
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Mais Acessados (Por Tempo de Acesso)</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart
									id={`grafico-barra-mais-acessado-tempo`}
									height={400}
									data={lista}
									labelPrefixo={null}
									labelSufixo={' min'} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	graficoMaisAcessadosQuantidade() {
		

		let lista = ['Acessos (Quantidade)'].map(item => {
			return {
				serie: item,
				categorias: this.props.listaMaisAcessadosQuantidade.map((categoria, i) => ({
					ordem: i + 1,
					nome: `${categoria.tela}`,
					quantidade: categoria.quantidade
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Mais Acessados (Por Quantidade de Acesso)</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart
									id={`grafico-barra-mais-acessado-quantidade`}
									height={400}
									data={lista}
									labelPrefixo={null}
									labelSufixo={null} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

	graficoMaisAcessadosUsuario() {
		

		let lista = ['Acessos (Usuários)'].map(item => {
			return {
				serie: item,
				categorias: this.props.listaMaisAcessadosUsuario.map((categoria, i) => ({
					ordem: i + 1,
					nome: `${categoria.tela}`,
					quantidade: categoria.usuarios
				}))
			}
		});

		return (
			<div>
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title'>Mais Acessados (Por Usuário que Acessou)</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							<Grid cols='12 12 12 12'>
								<BarChart
									id={`grafico-barra-mais-acessado-usuario`}
									height={400}
									data={lista}
									labelPrefixo={null}
									labelSufixo={null} />
							</Grid>
						</Row>
					</ContentCardBody>
				</ContentCard>				
			</div>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.analiseAcesso.modoTela,
	filtro: state.analiseAcesso.filtro,
	aguardando: state.analiseAcesso.aguardando,
	listaUsuario: state.analiseAcesso.listaUsuario,
	listaMaisAcessadosTempo: state.analiseAcesso.listaMaisAcessadosTempo,
    listaMaisAcessadosQuantidade: state.analiseAcesso.listaMaisAcessadosQuantidade,
    listaMaisAcessadosUsuario: state.analiseAcesso.listaMaisAcessadosUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setFiltro, getListaUsuario,
	getListaMaisAcessadosTempo, getListaMaisAcessadosQuantidade, getListaMaisAcessadosUsuario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AnaliseAcesso);
