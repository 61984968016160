import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
    aguardando: false,
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	},
    listaUsuario: [],
    listaMaisAcessadosTempo: [],
    listaMaisAcessadosQuantidade: [],
    listaMaisAcessadosUsuario: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

		case 'ANALISE_ACESSO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'ANALISE_ACESSO_FILTRO':
            return {
				...state,
				filtro: action.payload.filtro
			};
        
        case 'ANALISE_ACESSO_USUARIO_LISTADO':
            return {
				...state,
				listaUsuario: action.payload.data
			};

        case 'ANALISE_ACESSO_MAIS_ACESSADOS_TEMPO_LISTADO':
            return {
				...state,
				listaMaisAcessadosTempo: action.payload.data
			};

        case 'ANALISE_ACESSO_MAIS_ACESSADOS_QUANTIDADE_LISTADO':
            return {
                ...state,
                listaMaisAcessadosQuantidade: action.payload.data
            };

        case 'ANALISE_ACESSO_MAIS_ACESSADOS_USUARIO_LISTADO':
            return {
                ...state,
                listaMaisAcessadosUsuario: action.payload.data
            };

        default:
            return state;
    }
}
