
import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	modoTela: 'lista',
	lista: [],
	listaTipo: [],
	listaMotorista: [],
	listaGravidade: [],
	listaLocal: [],
	listaResponsabilidade: [],
	listaTipoCusto: [],
	listaUsuario: [],
	listaStatus: [],
	listaAcao: [],
	listaHistorico: [],
	listaCategoria: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		tipo_periodo: 'datahora_ocorrencia'
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'OCORRENCIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'OCORRENCIA_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

		case 'OCORRENCIA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'OCORRENCIA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'OCORRENCIA_HISTORICO_LISTADO':
            return {
				...state,
				listaHistorico: action.payload.data
			};

        case 'OCORRENCIA_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};

        case 'OCORRENCIA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
			};

        case 'OCORRENCIA_GRAVIDADE_SELECT_LISTADO':
			return {
				...state,
				listaGravidade: action.payload.data
			};

        case 'OCORRENCIA_LOCAL_SELECT_LISTADO':
			return {
				...state,
				listaLocal: action.payload.data
			};

        case 'OCORRENCIA_RESPONSABILIDADE_SELECT_LISTADO':
			return {
				...state,
				listaResponsabilidade: action.payload.data
			};

        case 'OCORRENCIA_TIPO_CUSTO_SELECT_LISTADO':
			return {
				...state,
				listaTipoCusto: action.payload.data
			};

		case 'OCORRENCIA_STATUS_SELECT_LISTADO':
			return {
				...state,
				listaStatus: action.payload.data
			};

		case 'OCORRENCIA_ACAO_SELECT_LISTADO':
			return {
				...state,
				listaAcao: action.payload.data
			};

		case 'OCORRENCIA_USUARIO_SELECT_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
			};

		case 'OCORRENCIA_CATEGORIA_SELECT_LISTADO':
			return {
				...state,
				listaCategoria: action.payload.data
			};
	
        default:
            return state;
    }
}
