import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './comissaoApuracaoForm';
import Aguardando from '../common/template/aguardando';
import FormatUtils from '../common/formatUtils/FormatUtils';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { 
	setModoTela, initForm, salvar, excluir, getLista,
	getListaComissaoOperacao, getListaComissaoTermo, getListaComissaoVinculo, 
	getListaUsuario, getListaComissaoTipo
} from './comissaoApuracaoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

class ComissaoApuracao extends Component {

	state = {
		linhaSelecionada: null,
		filtro: {
			data_inicial: `01/01/${new Date().getFullYear()}`,
			data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
			id_usuario: null 
		}
	}

    componentWillMount() {
        this.props.getLista(this.state.filtro.data_inicial, this.state.filtro.data_final);
		this.props.getListaComissaoOperacao();
		this.props.getListaComissaoTermo();
		this.props.getListaComissaoVinculo();
		this.props.getListaUsuario();
		this.props.getListaComissaoTipo();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		/*
		conquista_30_60: "0.000"
		conquista_integral: "0.000"
		conquista_mais_60: "0.000"
		data_base: "2021-07-01T03:00:00.000Z"
		data_fim: "9999-12-31T03:00:00.000Z"
		data_inicio: "2024-07-01T03:00:00.000Z"
		id_colaborador: 179
		id_operacao: 575
		id_termo: 2
		id_tipo: 5
		id_vinculo: 2
		manutencao_30_60: "0.000"
		manutencao_integral: "0.000"
		manutencao_mais_60: "0.000"
		nome_colaborador: "TRANSPORTES RODOVIÁRIOS LETSARA LTDA"
		nome_operacao: "9999 - 1EXCECAO_GERAL"
		nome_termo: "Entrega"
		nome_tipo: "Interno"
		nome_vinculo: "Gestor"
		*/
		
		let lista = this.props.lista.map(item => {

			let comissaoOperacao = this.props.listaComissaoOperacao.filter(comissao => comissao.codigo_operacao == item.id_operacao)[0];
			if (comissaoOperacao) {

				let dataEmissao = new Date(item.data_emissao);
				let dataBase = new Date(comissaoOperacao.data_base);
				let dataInicio = new Date(comissaoOperacao.data_inicio);
				let dataFim = new Date(comissaoOperacao.data_fim);
				let valorDocumento = parseFloat(item.valor_documento_contas_receber || 0);

				if (dataEmissao.getTime() >= dataInicio.getTime() && dataEmissao.getTime() <= dataFim.getTime() && dataEmissao.getTime() >= dataBase.getTime()) {

					let diferencaDias = parseInt((dataEmissao.getTime() - dataBase.getTime()) / 1000 / 60 / 60 / 24);

					let classificacao = '';
					let percentual = 0;

					//Se é menor que 180 dias é conquista, se é maior é manutenção
					if (diferencaDias <= 180) {
						if (diferencaDias <= 30) {
							classificacao = 'Conquista Integral';
							percentual = parseFloat(comissaoOperacao.conquista_integral || 0);
						} else if (diferencaDias <= 60) {
							classificacao = 'Conquista 30 até 60';
							percentual = parseFloat(comissaoOperacao.conquista_30_60 || 0);
						} else {
							classificacao = 'Conquista Mais de 60';
							percentual = parseFloat(comissaoOperacao.conquista_mais_60 || 0);
						}
					} else {
						const diasBase = 180;
						if (diferencaDias <= diasBase + 30) {
							classificacao = 'Manutenção Integral';
							percentual = parseFloat(comissaoOperacao.manutencao_integral || 0);
						} else if (diferencaDias <= diasBase + 60) {
							classificacao = 'Manutenção 30 até 60';
							percentual = parseFloat(comissaoOperacao.manutencao_30_60 || 0);
						} else {
							classificacao = 'Manutenção Mais de 60';
							percentual = parseFloat(comissaoOperacao.manutencao_mais_60 || 0);
						}
					}

					if (percentual > 0.0000) {
						return {
							...item,
							nome_vinculo: comissaoOperacao.nome_vinculo,
							id_colaborador: comissaoOperacao.id_colaborador,
							nome_representante: comissaoOperacao.nome_colaborador,
							tipo_emissao: comissaoOperacao.nome_termo,
							tipo_comissao: comissaoOperacao.nome_tipo,
							classificacao: classificacao,
							percentual_comissao: percentual,
							valor_comissao: parseFloat(((valorDocumento * percentual) / 100).toFixed(4))
						};
					}
				}
			}

			return null;
			
		}).filter(item => item && (
			!this.state.filtro.id_usuario
			|| item.id_colaborador == this.state.filtro.id_usuario
		));

		/*//Sem vínculo
		if (this.state.id_status == 2) {

			let listaAux = this.props.lista.map(item => item).filter(item => !this.state.id_vinculo || item.id_vinculo == this.state.id_vinculo);
			let vinculo = this.props.listaComissaoVinculo.filter(item => item.id == this.state.id_vinculo)[0];

			lista = this.props.listaApuracao.filter(item => {
				return listaAux.filter(itemAux => itemAux.id_operacao == item.id).length <= 0;
			}).map(item => ({
				id_operacao: item.id,
				nome_operacao: item.valor,
				id_vinculo: vinculo ? vinculo.id : null,
				nome_vinculo: vinculo ? vinculo.valor : ''
			}));

		} else {
			lista = this.props.lista.filter(item => {

				if (this.state.id_vinculo && item.id_vinculo != this.state.id_vinculo) {
					return false;
				}
				return true;
			});
		}

		lista = lista.filter(item => {

			if (this.state.pesquisar
				&& !(`		
					${item.nome_operacao}
					${DateFormat.formatarDataSqlParaTela(item.data_inicio)} ${DateFormat.formatarDataSqlParaTela(item.data_fim)}
					${item.nome_termo} ${item.nome_vinculo} ${item.nome_colaborador} ${item.tipo_emissao}
				`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});*/

		let valorComissaoTotal = 0;

		return (
			<ContentCard>
				<ContentCardHeader>
					{/*<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<ExcelFile
								filename={'Comissão - Operação'}
								element={
									<Button
										text='Exportar'
										type={'primary'}
										icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={lista.map(item => ({
									...item,
									data_base: DateFormat.formatarDataSqlParaTela(item.data_base),
									data_inicio: DateFormat.formatarDataSqlParaTela(item.data_inicio),
									data_fim: DateFormat.formatarDataSqlParaTela(item.data_fim),
									conquista_integral: parseFloat(item.conquista_integral),
									conquista_30_60: parseFloat(item.conquista_30_60),
									conquista_mais_60: parseFloat(item.conquista_mais_60),
									manutencao_integral: parseFloat(item.manutencao_integral),
									manutencao_30_60: parseFloat(item.manutencao_30_60),
									manutencao_mais_60: parseFloat(item.manutencao_mais_60)
								}))} name='Comissão - Operação'>
									<ExcelColumn label='Operação' value='nome_operacao' />
									<ExcelColumn label='Data Base' value='data_base' />
									<ExcelColumn label='Início Vigência' value='data_inicio' />
									<ExcelColumn label='Fim Vigência' value='data_fim' />
									<ExcelColumn label='Termo' value='nome_termo' />
									<ExcelColumn label='Vínculo' value='nome_vinculo' />
									<ExcelColumn label='Colaborador' value='nome_colaborador' />
									<ExcelColumn label='Tipo' value='nome_tipo' />
									<ExcelColumn label='Conquista (Integral)' value='conquista_integral' type='number' />
									<ExcelColumn label='Conquista (30 até 60)' value='conquista_30_60' type='number' />
									<ExcelColumn label='Conquista (Mais de 60)' value='conquista_mais_60' type='number' />
									<ExcelColumn label='Manutenção (Integral)' value='manutencao_integral' type='number' />
									<ExcelColumn label='Manutenção (30 até 60)' value='manutencao_30_60' type='number' />
									<ExcelColumn label='Manutenção (Mais de 60)' value='manutencao_mais_60' type='number' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>*/}
				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<Select
							label='Colaborador'
							options={this.props.listaUsuario}
							cols='12 6 3 1'
							placeholder='Selecione'
							minWidth={300}
							value={this.state.filtro.id_usuario}
							onChange={value => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										id_usuario: value
									}
								});
							}} />

						<LabelAndInputMask
							label='Data Incial' placeholder='Informe a data'
							cols='12 6 2 1'
							mask='99/99/9999'
							minWidth={130}
							maxWidth={130}
							value={this.state.filtro.data_inicial}
							onChange={data => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										data_inicial: data.target.value 
									}
								});
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 1'
							mask='99/99/9999'
							minWidth={130}
							maxWidth={130}
							value={this.state.filtro.data_final}
							onChange={data => {
								this.setState({
									...this.state,
									filtro: {
										...this.state.filtro,
										data_final: data.target.value 
									}
								});
							}} />

						<div style={{ marginTop: 8 }}>
							<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
								onClick={() => {
									this.props.getLista(this.state.filtro.data_inicial, this.state.filtro.data_final);
								}}>
								<i class='fas fa-sync-alt'></i> Atualizar
							</a>
						</div>
					</Row>

					{/*					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />*/}

					<Table responsive border>
						<THead>
							<Tr>
								<Th alignCenter small>Função</Th>
								<Th alignCenter small>Representante</Th>
								<Th alignCenter small>Emissão</Th>
								<Th alignCenter small>Tipo</Th>
								<Th alignCenter small>Documento</Th>
								<Th alignRight small>Doc(R$)</Th>
								<Th alignRight small>Recebido(R$)</Th>
								<Th alignCenter small>Tp.Emis.</Th>
								<Th alignCenter small>Operação</Th>
								<Th alignCenter small>Fatura</Th>
								<Th alignCenter small>Cliente Fatura</Th>
								<Th alignCenter small>Vencimento</Th>
								{/* <Th alignCenter small>Pagamento</Th> */}
								{/* <Th alignCenter small>Liquidação</Th> */}
								{/* <Th alignCenter small>Dias Atraso</Th> */}
								{/* <Th alignCenter small>Serviço</Th> */}
								<Th alignCenter small>Tipo Comissão</Th>
								<Th alignCenter small>Classificação</Th>
								<Th alignCenter small>Comissão(%)</Th>
								<Th alignRight small>Comissão(R$)</Th>
							</Tr>
						</THead>
						<TBody>
							{lista.map(item => {

								valorComissaoTotal += item.valor_comissao;

								return (
									<Tr key={`${item.data_emissao}-${item.numero_documento}`} className={`${this.state.linhaSelecionada == (item.id || item.id_operacao) ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id || item.id_operacao })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id || item.id_operacao })} >
										<Td minWidth={100} alignCenter small noWrap>{item.nome_vinculo}</Td>
										<Td minWidth={150} alignCenter small noWrap>{item.nome_representante}</Td>
										<Td minWidth={100} alignCenter small noWrap>{DateFormat.formatarDataSqlParaTela(item.data_emissao)}</Td>
										<Td minWidth={50} alignCenter small noWrap>{item.tipo_documento}</Td>
										<Td minWidth={50} alignCenter small noWrap>{item.numero_documento}</Td>
										<Td minWidth={100} alignRight small noWrap>{FormatUtils.formatarValorTelaDecimal(item.valor_documento_contas_receber, 2)}</Td>
										<Td minWidth={100} alignRight small noWrap>{FormatUtils.formatarValorTelaDecimal(item.valor_documento_amortizado, 2)}</Td>
										<Td minWidth={50} alignCenter small noWrap>{item.tipo_emissao}</Td>
										<Td minWidth={50} alignCenter small noWrap>{item.nome_operacao}</Td>
										<Td minWidth={50} alignCenter small noWrap>{item.numero_documento_contas_receber}</Td>
										<Td minWidth={200} alignCenter small noWrap>{item.cliente}</Td>
										<Td minWidth={100} alignCenter small noWrap>{DateFormat.formatarDataSqlParaTela(item.data_vencimento_contas_receber)}</Td>
										{/* <Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_pagamento)}</Td> */}
										{/* <Td alignCenter small>{DateFormat.formatarDataSqlParaTela(item.data_ultima_amortizacao)}</Td> */}
										{/* <Td alignCenter small>{item.dias_atraso}</Td> */}
										{/* <Td alignCenter small>{item.servico}</Td> */}
										<Td minWidth={100} alignCenter small noWrap>{item.tipo_comissao}</Td>
										<Td minWidth={100} alignCenter small noWrap>{item.classificacao}</Td>
										<Td minWidth={100} alignCenter small noWrap>{FormatUtils.formatarValorTela(item.percentual_comissao, 4)}</Td>
										<Td minWidth={100} alignRight small noWrap>{FormatUtils.formatarValorTelaDecimal(item.valor_comissao, 2)}</Td>
									</Tr>
								);
							})}
							<Tr>
								<Td colspan={15} alignRight bold>Total</Td>
								<Td alignRight bold>{FormatUtils.formatarValorTelaDecimal(valorComissaoTotal, 2)}</Td>
							</Tr>
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.comissaoApuracao.modoTela,
	aguardando: state.comissaoApuracao.aguardando,
	lista: state.comissaoApuracao.lista,
	listaComissaoOperacao: state.comissaoApuracao.listaComissaoOperacao,
	listaComissaoVinculo: state.comissaoApuracao.listaComissaoVinculo,
	listaUsuario: state.comissaoApuracao.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista,
	getListaComissaoOperacao, getListaComissaoTermo, getListaComissaoVinculo, 
	getListaUsuario, getListaComissaoTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ComissaoApuracao);
