import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import SelectDialog from '../common/form/selectDialog';
import { setError, setSuccess } from '../common/toast/toast';
import consts from '../consts';
import { setModoTela, initForm } from './integracaoLetsaraActions';
import ButtonTable from '../common/table/buttonTable';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';


class IntegracaoLetsaraForm extends Component {

	state = { placa: '' }

	componentDidMount() {

		const fileSelectorArquivo = document.createElement('input');
		fileSelectorArquivo.setAttribute('type', 'file');
		fileSelectorArquivo.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					this.props.initForm({
						...this.props.formularioValues,
						arquivo: reader.result
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorArquivo = fileSelectorArquivo;

	}

    componentWillMount() {

    }

    render() {

		let alterarSenha = this.props.alterarSenha;
		let readOnly = this.props.excluir || alterarSenha ? 'readOnly' : '';
		let acessoCliente = this.props.acessoCliente;

		let listaNegociadoresSelecionados = this.props.listaNegociador.filter(negociador => 
			(this.props.formularioValues.id_negociadores || []).filter(id_negociador => id_negociador == negociador.id).length
		);
		
		let listaOperacoes = this.props.listaOperacoes.filter(operacao => (
			listaNegociadoresSelecionados.filter(negociador => 
				(negociador.operacoes || []).filter(id_operacao => id_operacao == operacao.id).length
			).length
		));

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='data_validade'
								component={LabelAndInputMask}
								style={{ maxWidth: 200 }}
								label='Data de Validade *' placeholder='Informe a data'
								cols='12 6 4 4'
								mask='99/99/9999'
								readOnly={readOnly} />
							
							<SelectDialog
								options={this.props.listaNegociador}
								label='Negociadores'
								cols='12 12 12 12'
								placeholder='Selecione os negociadores'
								multiple
								readOnly={readOnly}
								value={(this.props.formularioValues.id_negociadores || [])}
								onChange={value => {

									let listaNegociadoresSelecionados = this.props.listaNegociador.filter(negociador => 
										(value || []).filter(id_negociador => id_negociador == negociador.id).length
									);
									
									this.props.initForm({
										...this.props.formularioValues,
										id_negociadores: (value || []),
										id_operacoes: (this.props.formularioValues.id_operacoes || []).filter(id_operacao => (
											listaNegociadoresSelecionados.filter(negociador => 
												(negociador.operacoes || []).filter(id_operacao_negociador => id_operacao_negociador == id_operacao).length
											).length
										))
									});
								}} />

							<SelectDialog
								options={listaOperacoes}
								label='Operações Logísticas'
								cols='12 12 12 12'
								placeholder='Selecione as operações'
								multiple
								readOnly={readOnly}
								value={(this.props.formularioValues.id_operacoes || [])}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										id_operacoes: (value || []).filter(id_operacao => (
											listaNegociadoresSelecionados.filter(negociador => 
												(negociador.operacoes || []).filter(id_operacao_negociador => id_operacao_negociador == id_operacao).length
											).length
										))
									});
								}} />
								
						</Row>

						<Row style={{ padding: 8 }}>
							<Grid cols='12 12 12 12'>
								<label>Termo</label>
							</Grid>
							{this.props.formularioValues.arquivo &&
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span class="btn btn-outline-primary fileinput-button dz-clickable">
									<img src={imagemAdd} style={{ height: 30 }} />
								</span>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
									<button
										type='button'
										class='btn btn-flat btn-outline-primary'
										style={{ width: '48%' }}
										onClick={() => {
											const link = document.createElement("a");
											link.href = this.props.formularioValues.arquivo;

											const match = this.props.formularioValues.arquivo.match(/^data:(.*);base64,/);
											const mimeType = match && match[1] ? match[1] : 'pdf';

											link.download = `termo_${this.props.formularioValues.id || new Date().getTime()}.${mimeType}`;
											document.body.appendChild(link);
											link.click();
											document.body.removeChild(link);
										}}>
										<i class='fas fa-download'></i>
									</button>

									<button
										type='button'
										class='btn btn-flat btn-outline-danger'
										style={{ width: '48%' }}
										disabled={readOnly}
										onClick={() => {
											this.props.initForm({
												...this.props.formularioValues,
												arquivo: null
											});
										}}>
										<i class='fas fa-trash-alt'></i>
									</button>
								</div>
							</div>}
							
							{(!readOnly && !this.props.formularioValues.arquivo) ? (
								<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo.click()}>
									<img src={imagemAdd} style={{ height: 30 }} />
								</span>
							) : null}
						</Row>
			
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

IntegracaoLetsaraForm = reduxForm({form: 'integracaoLetsaraForm', destroyOnUnmount: false})(IntegracaoLetsaraForm);
const mapStateToProps = state => ({
	sessao: state.auth.integracaoLetsara,
	formularioValues: getFormValues('integracaoLetsaraForm')(state),
	registro: state.integracaoLetsara.registro,
	listaNegociador: state.integracaoLetsara.listaNegociador,
	listaOperacoes: state.integracaoLetsara.listaOperacoes
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(IntegracaoLetsaraForm);
