import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './integracaoLetsaraForm';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import DateFormat from '../common/dateFormat/DateFormat';

import { setModoTela, initForm, salvar, excluir, getLista, getListaNegociador, getListaOperacoes } from './integracaoLetsaraActions';
import { setSuccess } from '../common/toast/toast';

class IntegracaoLetsara extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaNegociador();
		this.props.getListaOperacoes();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'alterarSenha' ? (
						<Form alterarSenha onSubmit={this.props.alterarSenha} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive alturaFixa maxHeight={window.innerHeight - 200}>
						<THead>
							<Tr backgroundColor={'#fff'}>
								<Th width={10} sticky alignCenter>ID</Th>
								<Th width={200} sticky>Nome</Th>
								<Th width={10} sticky alignCenter>Validade</Th>
								<Th width={10} sticky alignCenter>Token</Th>
								<Th width={10} sticky alignCenter>Termo</Th>
								<Th width={400} sticky alignCenter>Negociadores</Th>
								<Th width={400} sticky alignCenter>Operações Logísticas</Th>
								<Th width={200} sticky alignCenter>Usuário</Th>
								<Th width={10} sticky></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {

								let negociadores = (item.id_negociadores || []).map(item => {
									const negociador = this.props.listaNegociador.filter(itemAux => itemAux.id == item)[0];									
									return negociador ? negociador.valor : '';
								}).join(', ');

								let operacoes = (item.id_operacoes || []).map(item => {
									const operacao = this.props.listaOperacoes.filter(itemAux => itemAux.id == item)[0];									
									return operacao ? operacao.valor : '';
								}).join(', ');

								if (this.state.pesquisar
									&& !(`${item.nome} ${DateFormat.formatarDataSqlParaTela(item.data_validade)} ${negociadores || ''} ${operacoes || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{item.id}</Td>
									<Td>{item.nome}</Td>
									<Td alignCenter color='#fff' backgroundColor={item.valido ? '#3b8215' : '#c90028'}>{DateFormat.formatarDataSqlParaTela(item.data_validade)}</Td>
									<Td alignCenter>
										{item.valido &&
										<ButtonTable
											type={'primary'}
											icon={'fas fa-copy'}
											visible={true}
											event={() => {
												navigator.clipboard.writeText(item.token);
												setSuccess('Copiado com sucesso');
											}} />}
									</Td>
									<Td alignCenter>
										{item.arquivo &&
										<ButtonTable
											type={'secondary'}
											icon={'fas fa-file-download'}
											visible={true}
											event={() => {
												const link = document.createElement("a");
												link.href = item.arquivo;

												const match = item.arquivo.match(/^data:(.*);base64,/);
												const mimeType = match && match[1] ? match[1] : 'pdf';

												link.download = `termo_${item.id || new Date().getTime()}.${mimeType}`;
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
											}} />}
									</Td>
									<Td alignCenter small noWrap>{
										(item.id_negociadores || []).map(item => {
											const negociador = this.props.listaNegociador.filter(itemAux => itemAux.id == item)[0];									
											return negociador ? negociador.valor : '';
										}).join(', ')
									}</Td>
									<Td alignCenter small noWrap>{
										(item.id_operacoes || []).map(item => {
											const operacao = this.props.listaOperacoes.filter(itemAux => itemAux.id == item)[0];
											return operacao ? operacao.valor : '';
										}).join(', ')
									}</Td>
									<Td alignCenter>{item.nome_usuario_inclusao}</Td>
									<Td alignRight>
										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													data_validade: DateFormat.formatarDataSqlParaTela(item.data_validade)
												});
												this.props.initForm({
													...item,
													data_validade: DateFormat.formatarDataSqlParaTela(item.data_validade)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.integracaoLetsara,
	modoTela: state.integracaoLetsara.modoTela,
	lista: state.integracaoLetsara.lista,
	listaNegociador: state.integracaoLetsara.listaNegociador,
	listaOperacoes: state.integracaoLetsara.listaOperacoes
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaNegociador, getListaOperacoes }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(IntegracaoLetsara);
