import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './acessorioTransferenciaActions';

class AcessorioTransferenciaForm extends Component {

	state = {

	}

    componentWillMount() {

    }

	getImagemExemplo() {

		const tipo = this.props.listaTipo.filter(item => item. id == this.props.formularioValues.id_tipo)[0];
		
		return tipo && tipo.imagem ? tipo.imagem : null;
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaUnidadeFiltrada = this.props.listaUnidade.filter(item => item.id != this.props.formularioValues.id_unidade_origem);

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo'
								cols='12 12 12 12'
								placeholder='Selecione o tipo'
								readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
								
							{this.getImagemExemplo() &&
							<img src={this.getImagemExemplo()} style={{ padding: 8, height: 200 }} />}

							<Field
								name='id_evento'
								component={Select}
								options={this.props.listaEvento}
								label='Evento'
								cols='12 12 12 12'
								placeholder='Selecione o evento'
								readOnly={readOnly} />
							
							{(!this.props.formularioValues.id_veiculo_origem && this.props.usuarioCarregado.acessorio_transferencia_unidade) ? (
								<Field
									name='id_unidade_origem'
									component={Select}
									options={this.props.listaUnidade.filter(item => {

										if (this.props.sessao && this.props.sessao.id && this.props.sessao.id == 49) {
											return item.id == 4 || item.id == 7;
										} else if (this.props.sessao && this.props.sessao.id && this.props.sessao.id == 55) {
											return item.id == 6 || item.id == 9;
										} else {
											return true;
										}

									})}
									label='Unidade Origem'
									cols='12 12 12 12'
									placeholder='Selecione a unidade origem'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							) : null}
							{(!this.props.formularioValues.id_unidade_origem && this.props.usuarioCarregado.acessorio_transferencia_veiculo) ? (
								<Field
									name='id_veiculo_origem'
									component={Select}
									options={this.props.listaVeiculo}
									label='Veículo Origem'
									cols='12 12 12 12'
									placeholder='Selecione o veículo origem'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							) : null}
							
							{(!this.props.formularioValues.id_veiculo_destino && this.props.usuarioCarregado.acessorio_transferencia_unidade && listaUnidadeFiltrada.length > 0) ? (
								<Field
									name='id_unidade_destino'
									component={Select}
									options={listaUnidadeFiltrada}
									label='Unidade Destino'
									cols='12 12 12 12'
									placeholder='Selecione a unidade destino'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							) : null}
							{(!this.props.formularioValues.id_unidade_destino && this.props.usuarioCarregado.acessorio_transferencia_veiculo) ? (
								<Field
									name='id_veiculo_destino'
									component={Select}
									options={this.props.listaVeiculo}
									label='Veículo Destino'
									cols='12 12 12 12'
									placeholder='Selecione o veículo destino'
									readOnly={this.props.formularioValues.id ? 'readonly' : readOnly} />
							) : null}


							{this.props.formularioValues.id_veiculo_origem || this.props.formularioValues.id_veiculo_destino ? (
								<Field
									name='id_motorista'
									component={Select}
									options={this.props.listaMotorista}
									label='Motorista'
									cols='12 12 12 12'
									placeholder='Selecione o motorista'
									readOnly={readOnly} />
							) : null}
						
							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade' placeholder='Informe a quantidade'
								cols='12 12 4 4'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe as observações'
								cols='12 12 12 12'
								readOnly={readOnly} />

						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

AcessorioTransferenciaForm = reduxForm({form: 'acessorioTransferenciaForm', destroyOnUnmount: false})(AcessorioTransferenciaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	formularioValues: getFormValues('acessorioTransferenciaForm')(state),
	registro: state.acessorioTransferencia.registro,
	listaTipo: state.acessorioTransferencia.listaTipo.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_tipos || []).includes(item.id)
	),
	listaEvento: state.acessorioTransferencia.listaEvento,
	listaUnidade: state.acessorioTransferencia.listaUnidade.filter(item => 
		state.auth.usuarioCarregado && (state.auth.usuarioCarregado.acessorio_unidades || []).includes(item.id)
	),
	listaVeiculo: state.acessorioTransferencia.listaVeiculo,
	listaMotorista: state.acessorioTransferencia.listaMotorista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AcessorioTransferenciaForm);
