import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setError, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'INTEGRACAO_LETSARA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('integracaoLetsaraForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/integracaoLetsara`);
    return {
        type: 'INTEGRACAO_LETSARA_LISTADO',
        payload: request
    };
}

export function getListaLog(filtro) {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/integracaoLetsaraLog
			?id_integracao=${filtro.id_integracao || null}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(filtro.data_final)}
		`).then(result => {
			dispatch({
				type: 'INTEGRACAO_LETSARA_LOG_LISTADO',
				payload: result
			});
		}).catch(e => {
			setError('Erro ao realizar consulta');
		});;
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/integracaoLetsara`, {
				...registro,
				data_validade: DateFormat.formatarDataTelaParaSql(registro.data_validade),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/integracaoLetsara`, {
				...registro,
				data_validade: DateFormat.formatarDataTelaParaSql(registro.data_validade),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function getListaNegociador() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/reports/negociadores`)
		.then(resp => {
			dispatch({
		        type: 'INTEGRACAO_LETSARA_NEGOCIADOR_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaOperacoes() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/operacao/listarSelect`)
		.then(resp => {
			dispatch({
		        type: 'INTEGRACAO_LETSARA_OPERACAO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/integracaoLetsara?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
